import React from "react";

function TermAndCondition() {
  return (
    <div className="mx-6 mt-24 ">
      <div className="mb-4 mx-6">
        <p className="mb-8 font-poppins mt-6 mx-2">
          This document is an electronic record in terms of the Information
          Technology Act, 2000 and rules made there under. Further, this
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </p>

        <div className="mx-2">
          <h1 className=" font-bold text-2xl mb-4">
            BulkyBid TERMS & CONDITIONS
          </h1>
          <p className="mb-3">
            I. This BulkyBid terms of use ("<b>BulkyBid Terms</b>") I. is an
            electronic agreement between
            <b>bulkybid private limited </b>, a company incorporated under the
            provisions of the Companies Act, 1956, having its registered office
            at 101,P & T Colony, R T Nagar, Bangalore North - 560032 KARNATAKA
            ("<b>bulkybid</b>") and the you/Bulkybid BulkyBid, ("<b>BulkyBid</b>
            "). These BulkyBid Terms shall apply to all online bidding at
            Bulkybid sales conducted by or in conjunction with bulkybid ("
            <b>Online Bidding</b>"). By registering to participate in an
            Bulkybid sale conducted through the bulkybid's Platform ("
            <b>Bulkybid Sale</b>") you expressly agree to these BulkyBid Terms.
          </p>
          <p className="mb-3">
            III. Acceptance of these Terms and Conditions by you is an absolute
            pre-condition for you to transact on the Platform and use the
            functionality or services associated with the Platform.
            Consequently, if you do not wish to accept this BulkyBid Terms, you
            will not be permitted to use the Platform or Services.
          </p>
          <p className="mb-3">
            IV. This BulkyBid Terms is supplemental, meaning that it shall apply
            in addition to the Platform's Terms of Use ("<b>TOU</b>"), the
            special terms & conditions of Bulkybid Sale, Privacy Policy
            (collectively, the "<b>Bid Conditions</b>") and shall be deemed
            incorporated by reference and made a part of the Bid Conditions for
            such Bulkybid Sale. Any such special terms and conditions for an
            Bulkybid Sale are published online through the Platform in the
            catalogue for each Bulkybid Sale (the "<b>Catalogue</b>").
            Additional information applicable to each Bulkybid Sale may also be
            set out in the Catalogue, on our web site, and/or in a notice sent
            to the BulkyBid through SMS/Email on the registered phone number /
            email id of the BulkyBid, and it is BulkyBid's responsibility to
            review that information. In registering to bid at any Bulkybid Sale,
            you agree to the applicable Bid Conditions.
          </p>
          <p className="mb-5">
            V. If any terms of this BulkyBid Terms conflict with any other terms
            and conditions/document/electronic record, this BulkyBid Agreement
            shall prevail and supersede.
          </p>
        </div>
      </div>
      <h1 className="font-bold text-lg mb-4 mx-3">1. DEFINITIONS</h1>
      <div className="mx-9 mb-6">
        <p className="mb-4">
          <b>1.1 "Agreement"</b> shall mean these BulkyBid Terms, all schedules,
          appendices, annexures and exhibits attached to it or incorporated in
          it by reference;
        </p>
        <p className="mb-4">
          <b>1.2 "Backout Fee"</b> shall mean the amount payable by the BulkyBid
          to the Seller in case of the BulkyBid failing to pay the Winning Bid
          to the Seller as per the terms of the Bulkybid Sale;
        </p>
        <p className="mb-4">
          <b>1.3 "BulkyBid"</b> shall mean and include any natural or legal
          person or entity who is a registered member of the Platform and may
          have paid the requisite Membership Fee and Security Deposit amount to
          the bulkybid for use of the Platform and has completed the KYC
          documentation prescribed herein;
        </p>
        <p className="mb-4">
          <b>1.4 "Buyer Limit"</b> shall mean the maximum outstanding
          permissible as a sum of:
        </p>
        <div className="mb-4 mx-8">
          <p>
            (1.i) The aggregate amount of all the bids outstanding in live
            Bulkybids where the BulkyBid is presently winning the Bulkybid i.e.
            where the BulkyBid's bid is the highest bid;
          </p>
          <p>
            (1.ii) The amount of Winning Bids of the BulkyBid for a Vehicles
            where the Seller is yet to approve the Winning Bid;
          </p>
          <p>
            (1.iii) The amount of Winning Bid of the BulkyBid for vehicles in an
            Bulkybid Sale, where the Winning Bid is approved by the Seller and
            such amount is due and payable by the BulkyBid to the Seller;
          </p>
        </div>
        <p className="mb-4">
          <b>1.5"Confidential Information"</b> shall have the meaning ascribed
          to it in Clause 11.
        </p>
        <p className="mb-4">
          <b>1.6 "Seller"</b> shall mean and shall refer to the person/entity
          who transacts in the Vehicle through the Platform, being owner thereof
          or insurance companies, banks, financial institutions, new vehicle
          dealers acting on behalf of the owners, having the undisputed legal
          right and/or authority to sell the Vehicle and who has an agreement
          with the bulkybid for listing the Vehicle(s) for Bulkybid Sale on the
          Platform;
        </p>
        <p className="mb-4">
          <b>1.7 "Vehicle(s)"</b> shall mean the motor vehicle listed in an
          Bulkybid Sale conducted through the Platform which may include cars,
          passenger vehicles, commercial vehicles, construction equipment
          vehicles, farm vehicles, two wheelers, trailers, miscellaneous salvage
          vehicles including without limitation used, recovered,
          accident-damaged and/or insurance write-off vehicles that are posted
          for Bulkybid Sale on Platform.
        </p>
        <p className="mb-2">
          <b>1.8 "Winning Bid"</b> shall mean the highest bid in an Bulkybid
          Sale conducted through the Platform, and which is binding on the
          BulkyBid;
        </p>
      </div>
      <h1 className="font-bold text-lg mb-2 mx-3">2. INTRODUCTION</h1>
      <div className="mx-9 mb-6">
        <p className="mb-4">
          <b>2.1</b> The bulkybid is an online marketplace which has developed
          an online Platform enabling the registered BulkyBids to place their
          respective bids for Bulkybid Sale of Vehicle(s) conducted through the
          Platform on a real time basis.
        </p>
        <p className="mb-4">
          <b>2.2</b> Through Platform the bulkybid inter alia facilitates and
          enables a Seller who wish to transact in the Vehicles on the Platform,
          to find prospective buyers amongst the registered BulkyBids, who wish
          to buy the Vehicles transacted on the Platform through an online
          Bulkybid Sale.
        </p>
        <p className="mb-4">
          <b>2.3</b> The BulkyBid is desirous of participating in Online Bidding
          through the online Platform owned and developed by bulkybid.
        </p>
        <p className="mb-4">
          <b>2.4</b> BulkyBid acknowledges that bulkybid owns and operates an
          online Platform, which is essentially a marketplace to facilitate sale
          of Vehicle. BulkyBid further acknowledges that the Platform provides
          for bidding and Bulkybid of Vehicles, to be directly sold by the
          Sellers to the interested BulkyBids. BulkyBid acknowledges that
          bulkybid does not and will not engage in buying and selling of
          Vehicles for itself and/or in its own right.
        </p>
        <p className="mb-4">
          <b>2.5</b> BulkyBid acknowledges that the Seller may be an individual
          or firm or company or limited liability partnership and any other
          legal entity being set up in accordance with the respective applicable
          laws, who is desirous of selling their Vehicle(s) through the Bulkybid
          Sale conducted via the Platform.
        </p>
      </div>
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">
          3. MEMBERSHIP AND REGISTRATION
        </h1>
        <div className="mx-9 mb-6">
          <p className="mb-4">
            <b>3.1</b> Membership (as a registered BulkyBid) with bulkybid is
            open to all persons (a) having the capacity to enter into legally
            binding contracts as per the Indian Contract Act, 1872; (b) who are
            residents of the Republic of India; (c) who click the "
            <b>I have read these Terms and Conditions</b>" tab; and (d) who has
            paid the requisite Registration Fee, Membership Fee and has
            deposited the Security Deposit with bulkybid. bulkybid reserves the
            right to deny membership to, and/or exclude from the Platform, any
            person/BulkyBid in bulkybids' absolute discretion and bulkybid also
            reserve the right to suspend, revoke or amend your/BulkyBid's
            membership in bulkybid's absolute discretion.
          </p>
          <p className="mb-4">
            <b>3.2 </b>If you wish to become a registered BulkyBid and
            participate in Bulkybid Sale conducted through the Platform you must
            (a) fulfil KYC guidelines and provide documents for KYC
            verification; (b) pay a non-refundable annual Registration Fee of
            which you will be notified prior to you completing your membership
            application; and (c) deposit with the bulkybid a non-interest
            bearing, refundable security deposit the details of which you will
            be advised prior to you completing your registration ("
            <b>Security Deposit</b>"). Membership Fee and Security Deposit
            requirements may be changed at any time with or without notice.
            Currently applicable Membership Fee and Security Deposit
            requirements are available at www.Bulkybids.com.
          </p>
          <p className="mb-4">
            <b>3.3</b> BulkyBid's membership with bulkybid must be reviewed and
            renewed annually by paying annual membership fee ("
            <b>Membership Fee</b>") and providing information regarding any
            changes to your details. bulkybid reserve the right to change/alter
            the Registration Fee, annual Membership Fees or Security Deposit at
            any time with or without notice, though bulkybid will place such
            information on the Platform. BulkyBid's use of the Platform and/or
            Services thereafter will be deemed to be BulkyBid's express
            acceptance and/or acceptance by conduct of such increase in the
            Membership Fee and/or Security Deposit. bulkybid also reserve the
            right to waive or reduce the Registration Fee, Membership Fee and/or
            Security Deposit at any time as a standard offering or special
            promotion. Please note that the collection of Registration Fee,
            Membership Fee and Security Deposit by bulkybid from its members is
            only for the purposes of rendering continuous access to the Platform
            and provide services associated with Platform to all registered
            BulkyBids of the Platform and to safeguard bulkybid's rights against
            any breach of these BulkyBid Terms, other applicable terms and
            conditions of the Platform by its registered BulkyBids. bulkybid is
            not in any manner accepting any deposits, under the provisions of
            any law, and is not engaging in any banking or similar activity.
          </p>
          <p className="mb-4">
            <b>3.4</b> BulkyBid shall also be liable to pay membership fee to
            use the facility provided through the Platform, including
            participation in an Bulkybid through the Platform for a period of
            one (1) calendar year. BulkyBids are advised to annually renew their
            membership with the bulkybid for continuous use of the Platform and
            its Services by paying annual membership fee ("<b>Membership Fee</b>
            ").
          </p>
          <p className="mb-4">
            <b>3.5 Security Deposit</b>: In order to participate in an Bulkybid
            Sale a BulkyBid is also required to submit , a non-interest bearing
            refundable security deposit ("<b>Security Deposit</b>") of a minimum
            amount of Rs. 10,000/- (Rupees Ten Thousand only). Such Security
            Deposit so deposited by the BulkyBid will be refunded to the
            respective BulkyBid upon expiry or earlier termination of this
            Agreement or on the instructions of the BulkyBid after adjustment of
            any charge/Membership Fee or dues payable by BulkyBid to the
            bulkybid or Seller in accordance with the terms hereof.
          </p>
          <p className="mb-4">
            <b>3.6</b> Upon successful completion of the registration process
            and payment of requisite Registration Fee, Membership Fee and
            Security Deposit to the bulkybid and completion of KYC
            documentation, the bulkybid will provide a distinct user id and
            password to the BulkyBid for accessing the BulkyBid's account ("
            <b>BulkyBid Account</b>") on the Platform.
          </p>
          <p className="mb-4">
            <b>3.7</b> Subject to successful registration and payment of
            requisite Membership Fee and Security Deposit each BulkyBid will be
            allocated a maximum monetary limit i.e. Buyer Limit upto which the
            BulkyBid is allowed to participate in an Bulkybid Sale. Respective
            Buyer Limit of each BulkyBid will be displayed on the dashboard of
            BulkyBid Account on the Platform. Buyer Limit may be increased or
            decreased by bulkybid from time to time at the sole discretion of
            bulkybid without any prior notice to the BulkyBid. At the sole
            discretion of the bulkybid, without any liability to do so, each
            BulkyBid may be typically allowed a Buyer Limit of upto 50 times of
            the outstanding and unallocated Security Deposit of the BulkyBid,
            deposited with the bulkybid. The bulkybid may, from time to time at
            its sole discretion, allow a Buyer Limit in excess of 50 times of
            the outstanding and unallocated Security Deposit. For the sake of
            clarification it is stated that portions of the Security Deposit may
            be earmarked against due but unpaid Membership Fee, due but unpaid
            Success Fee and/or security deposit to be retained against a Winning
            Bid.
          </p>
          <p className="mb-4">
            <b>3.8</b> In the event that a BulkyBid wishes to increase the Buyer
            Limit, BulkyBid is required to submit with the bulkybid such
            additional amount of non-interest bearing refundable Security
            Deposit as may be prescribed by bulkybid.
          </p>
          <p className="mb-4">
            <b>3.9</b> BulkyBid shall deposit the Security Deposit with the
            bulkybid through the authorised banking channels including without
            limitation cheque/draft/NEFT/RTGS/IMPS only. Any change in Buyer
            Limit corresponding to the additional Security Deposit may take up
            to 2 working days to reflect in the BulkyBid's Bulkybid account
            after the additional security deposit amount is credit to the bank
            account of the bulkybid.
          </p>
          <p className="mb-4">
            Subject to fulfilment of the a BulkyBid's payment obligations
            including without limitation the Payment of Registration Fee,
            Membership Fee, Total Price, Success Fee and other dues payable by
            BulkyBid to bulkybid or Seller, a BulkyBid may request bulkybid for
            refund of the unapplied Security Deposit or a part thereof. A
            BulkyBid may request a refund of any unapplied Security Deposit
            online by visiting the respective BulkyBid's account information
            page on the Platform or sending an email from the registered email
            ID. All refunds are subject to confirmation that a security deposit
            is not applicable to any open bids or unpaid charges. bulkybid will
            within 10 working days of such request, remit to the authorised
            account of the BulkyBid the unapplied Security Deposit or part
            thereof as per request of the BulkyBid after adjustment of dues and
            charges payable by BulkyBid to the bulkybid or Seller.
          </p>
          <p className="mb-4">
            <b>3.10</b> BulkyBid understands and agrees that the bulkybid may at
            its discretion shall have right to forfeit the entire Security
            Deposit or part thereof and adjust it against any charges and dues
            which are outstanding and payable by the BulkyBid to the bulkybid or
            Seller including but not limited to non-payment of the amount of
            Total Price to the Seller within the prescribed time period, refusal
            to honour the Total Price including the Winning Bid, non-payment of
            Success Fee to the bulkybid or violation of any other term of this
            Agreement resulting in any loss or damage to the bulkybid or Seller.
            The BulkyBid hereby expressly agrees and authorise the bulkybid for
            adjustment of such outstanding dues and charges payable by BulkyBid
            to the bulkybid or Seller.
          </p>
        </div>
      </div>
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">4. Bulkybid CONDITIONS</h1>
        <div className="mx-9 mb-6">
          <p className="mb-4">
            <b>4.1</b> BulkyBid acknowledges that the Seller in its sole and
            absolute discretion reserves the right to reject or void bids for
            any reason whatsoever. Seller also reserves the right to reject any
            and all the bids or to accept a bid which is not the highest bid
            amount in any Bulkybid Sale conducted through the Platform. In the
            event of a dispute pertaining to a bid or Bulkybid Sale, Seller
            and/or bulkybid shall be the exclusive deciding authority with sole
            and absolute discretion in resolving disputes.
          </p>
          <p className="mb-4">
            <b>4.2</b> Seller or bulkybid may, in its sole and absolute
            discretion and with or without notice, postpone or cancel an
            Bulkybid Sale or withdraw a Vehicle from Bulkybid Sale. bulkybid or
            Seller, as the case may be, will have no liability or obligation
            whatsoever to BulkyBid as a result of any withdrawal postponement or
            cancellation of an Bulkybid.
          </p>
          <p className="mb-4">
            <b>4.3</b> Seller or bulkybid reserves the right to cancel an
            Bulkybid Sale in the event of fraud, material misrepresentation, or
            any material defect in the Vehicle description or bidding
            information, as determined by Seller and/or bulkybid in its sole
            discretion.
          </p>
          <p className="mb-4">
            <b>4.4</b> The Winning Bid shall be binding on the BulkyBid. In the
            event of the BulkyBid not honouring the Winning Bid, the bulkybid
            has the right to proceed for Suspension and Termination under Clause
            14 of this Agreement.
          </p>
          <p className="mb-4">
            <b>4.5</b> In the event that an Bulkybid Sale is cancelled after the
            BulkyBid has remitted the Total Price, the sole and exclusive remedy
            available to the BulkyBid shall be to claim the refund of Total
            Price remitted to the Seller's account.
          </p>
        </div>
      </div>
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">
          5. PAYMENT OF WINNING BID
        </h1>
        <div className="mx-9 mb-6">
          <p className="mb-4">
            <b>5.1</b> Upon the Seller's communication of its acceptance of the
            BulkyBid's Winning Bid for an Bulkybid Sale, the BulkyBid shall pay
            to the Seller the amount equivalent to the Winning Bid along with
            the applicable taxes ("Total Price") within the time period
            stipulated in the terms of Bulkybid Sale. In the absence of any
            specific period mentioned in the Bulkybid Sale, the BulkyBid shall
            pay the Total Price to the Seller within three (3) working days from
            receipt of confirmation/Winning Bid acceptance from the Seller. For
            avoidance of doubt it is clarified that upon acceptance of the
            Winning Bid by the Seller, the respective BulkyBid of the Winning
            Bid shall pay an amount equivalent to the Total Price in the manner
            as mentioned above and such BulkyBid will not be allowed to rescind
            the Winning Bid for any reason whatsoever. In the event of a
            BulkyBid's failure to make the payment of Total Price to the Seller
            as per the terms of the Bulkybid Sale, the same shall be treated as
            material breach of this BulkyBid Terms and respective terms and
            conditions of the Bulkybid Sale and bulkybid and/or Seller shall
            have right to initiate appropriate legal proceedings against the
            BulkyBid, and BulkyBid alone shall be liable as to the cost, expense
            and consequences thereof.
          </p>
          <p className="mb-4">
            <b>5.2 </b>In the event of a BulkyBid's failure to make payment for
            Total Price to the Seller within the stipulated time, BulkyBid
            agrees that the bulkybid or Seller may in their sole and absolute
            discretion without any liability to do so, cancel the Winning Bid
            and offer the relevant Vehicle for re-sale without any notice to the
            BulkyBid. In such case, BulkyBid shall be liable for payment of the
            applicable listing fee or cost incurred by Seller or bulkybid for
            re-sale of the Vehicle.
          </p>
          <p className="mb-4">
            <b>5.3</b> Further, in the event of BulkyBid's failure to make the
            payment of the Total Price as per Clause 5.1 within the stipulated
            time, bulkybid may in its sole discretion suspend the BulkyBid's
            membership and/or forfeit whole or part of the Security Deposit
            deposited by BulkyBid. In such case, a BulkyBid shall be eligible to
            bid on any new Bulkybid Sale only after replenishing the amount of
            Security Deposit and/or payment of Reactivation Fee.
          </p>
          <p className="mb-4">
            <b>5.4</b>If any of the events described under Clause 5.1, 5.2 or
            5.3 occurs an excessive number of times, the bulkybid may at its
            sole discretion revoke BulkyBid's membership in bulkybid's absolute
            discretion.
          </p>
        </div>
      </div>
      {/* 6. SUCCESS FEE */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">6. SUCCESS FEE</h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            The BulkyBid shall pay to the bulkybid a transaction fee for every
            Bulkybid Sale ("Transaction Fee"). Such Transaction Fee shall be due
            and payable only upon successfully winning an Bulkybid. BulkyBid
            agrees to pay Transaction Fee immediately upon the confirmation by
            the Seller of the acceptance of BulkyBid's Winning Bid. The
            Transaction Fee for each Bulkybid Sale and Vehicle may differ and
            the same shall be notified on the Platform or as part of additional
            terms of respective Bulkybid Sale. In the absence of any specific
            Transaction Fee mentioned for a particular Bulkybid Sale, a standard
            Transaction Fee equivalent to 4.00% of the Winning Bid shall be
            payable by the BulkyBid to the bulkybid. Transaction Fee shall be
            exclusive of applicable taxes.
          </p>
        </div>
      </div>
      {/* 7. DISCLAIMERS */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">DISCLAIMERS</h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            <b>7.1</b> Except as otherwise expressly provided by applicable law,
            all Vehicles sold via Bulkybid Sale through Platform are sold "
            <b>
              AS-IS WHERE-IS" BASIS WITHOUT ANY WARRANTY, EXPRESS OR IMPLIED,
              INCLUDING BUT NOT LIMITED TO ANY WARRANTY OF FITNESS FOR A
              PARTICULAR PURPOSE OR MERCHANTABILITY
            </b>
            ".
          </p>
          <p className="mb-4">
            <b>7.2 </b>BulkyBid acknowledges that all Service provided by
            bulkybid hereunder are provided "AS IS" and "WHERE IS" and without
            any, warranty whatsoever and that BulkyBid's use of the Portal and
            services associated therewith are at BulkyBid's own risk. Neither
            bulkybid nor any of bulkybid's affiliates licensors or suppliers
            makes and BulkyBid is not receiving any warranties, express, implied
            or otherwise with respect to the Portal or services provided
            hereunder. bulkybid and its affiliates, licensors and suppliers
            expressly disclaim all implied warranties and conditions of
            merchantability, fitness for a particular purpose, title and/or
            non-infringement. bulkybid is not involved in any transaction
            between Seller and BulkyBid. In the event BulkyBid has any dispute
            with the Seller, BulkyBid hereby releases bulkybid, its officers,
            directors, employees, agents, affiliates and its shareholders from
            any and all claims demands and damages (actual and consequential,
            direct, indirect and otherwise) of every kind and nature, known and
            unknown, suspected and unsuspected, disclosed or undisclosed,
            arising out of or in any way connected with any such dispute.
          </p>
          <p className="mb-4">
            <b>7.3</b> The bulkybid expressly disclaims the accuracy or
            completeness of any and all information provided to BulkyBids
            regarding Vehicles, whether provided in written, verbal, or digital
            image form through Catalogue available on the Platform. Vehicle
            Information provided by Seller through the Platform is for
            convenience only. BulkyBid shall not rely on Vehicle Information in
            deciding whether or how much to bid on a Vehicle offered for sale
            through Platform and BulkyBid is advised to exercise its discretion
            thereto. Vehicle Information includes but is not limited to: year,
            make, model, condition, damage amount, damage type, drivability,
            accessories, mileage, odometer disclosures, vehicle identification
            number (e.g. engine and chasis number and serial number), title,
            repair cost, repair history, title history, and total loss history.
          </p>
          <p className="mb-4">
            <b>7.4</b> bulkybid make no representations or warranties about the
            accuracy, reliability, completeness, and/or timeliness of any
            content, information, software, text, graphics, links,
            communications or Catalogue provided on or through the use of the
            Platform or that the operation of the Platform or that the operation
            of Platform will be error free and/or uninterrupted. Consequently,
            the bulkybid assumes no liability whatsoever for any monetary or
            other loss or damage suffered by a BulkyBid on account of:
          </p>
          <div className="mx-5 mb-4">
            <p>
              (i) The delay, failure, interruption, or corruption of any data or
              other information transmitted in connection with use of the
              Platform.
            </p>
            <p>
              (ii) Any interruption or errors in the operation of the Platform.
            </p>
          </div>
          <p className="mb-4">
            <b>7.5</b> The bulkybid only claims responsibility for the accuracy
            of the inspection report provided by it on the platform, if any, as
            at the date and time when the inspection was carried out by the
            representative of the bulkybid. The bulkybid shall not be
            responsible or liable for any changes to the Vehicle or its
            condition after the completion of the said inspection
          </p>
          <p className="mb-4">
            <b>7.6</b> bulkybid and respective Vehicle Seller expressly disclaim
            any and all representations, warranties, and guarantees regarding
            Vehicles sold through Bulkybid Sale on the Platform.
          </p>

          <p className="mb-4">
            <b>7.7</b> It is the sole responsibility of the BulkyBid to
            ascertain, confirm, research, inspect, and/or investigate Vehicles
            and any and all Vehicle Information prior to bidding on vehicles.
          </p>
          <p className="mb-4">
            <b>7.8</b> All BulkyBids acknowledge and agree that Vehicles are
            sold through the Platform on AS IS basis and are not represented as
            being in a road worthy condition, mechanically sound, or maintained
            at any guaranteed level of quality. The vehicles may not be fit for
            use as a means of transportation and may require substantial repairs
            and expense.
          </p>
          <p className="mb-4">
            <b>7.9 </b>Disclosure of BulkyBid Information. Each BulkyBid
            expressly agrees that bulkybid may, if and to the extent necessary
            to comply with applicable law, disclose information regarding
            BulkyBid and regarding transactions conducted by BulkyBid through
            Platform if requested to do so in any judicial or administrative
            proceeding or by any governmental agency or regulatory authority.
          </p>
        </div>
      </div>
      {/*8. PROPRIETORY RIGHT: SCOPE OF USE  */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">
          8. PROPRIETORY RIGHT: SCOPE OF USE
        </h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            BulkyBid acknowledges and agree that bulkybid owns, solely and
            exclusively, all right, title interest in and to the Platform,
            including without limitation all content, code data, information,
            graphics, and other intellectual property used in or on the
            Platform, the look and feel, design and organization of the
            Platform, and all copyright, trademark, trade name, service mark,
            patent, trade secret, moral, database and other intellectual
            property and proprietary rights inherent therein or appurtenant
            thereto. Subject to the provisions of this Agreement, including,
            without limitation, BulkyBid's payments of all Registration Fee,
            Membership Fee and Security Deposit and other charges, bulkybid
            hereby grants to BulkyBid a limited, non-exclusive, non-assignable,
            non-transferable license during the Term of this Agreement to access
            and view approved materials and information from the bulkybid's
            Platform remotely over the internet, solely to enable BulkyBid to
            participate in online Bulkybid conducted through the Platform and
            solely using the BulkyBids' Account username and password provided
            to BulkyBid by bulkybid. Any other use of the Platform, BulkyBid's
            account or any content, code, information, data or other materials
            contained therein or available through the Platform is strictly
            prohibited. Any third party's trademark, logos or other intellectual
            property that may appear on Platform are the property of their
            respective owners and may not be used without such third party's
            written consent. All rights not expressly granted to BulkyBid
            hereunder are reserved exclusively to bulkybid.
          </p>
        </div>
      </div>
      {/* MISCELLANEOUS */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">MISCELLANEOUS</h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            <b>9.1</b> BulkyBid agrees to comply, and to cause its agents and
            representatives to comply, with all applicable laws and regulations
            when removing a Vehicle from a Seller's facility, including properly
            loading and securing all Vehicles for safe travel. bulkybid or
            Seller shall in no event be responsible or liable for any damage
            occurring as a result of unsafe or improper loading or securing of a
            vehicle for transport and the BulkyBid agrees to indemnify, defend,
            and hold bulkybid harmless from any and all claims, damages, losses,
            liabilities, obligations, costs and expenses (including attorneys'
            fees) arising from or related to any failure of BulkyBid or the
            BulkyBid's agent or representative to properly load and/or secure a
            Vehicle for transport.
          </p>
          <p className="mb-4">
            <b>9.2</b> Once a Vehicle is removed from Seller's premises it is
            accepted AS-IS, and under no circumstances will bulkybid or Seller
            be liable for subsequent claims of damage or loss of any kind or
            nature whatsoever.
          </p>
          <p className="mb-4">
            <b>9.3</b> bulkybid reserves the right to suspend or revoke a
            Member's bidding privileges for any reason, in its sole and absolute
            discretion.
          </p>
          <p className="mb-4">
            <b>9.5</b> BulkyBid hereby agrees that the bulkybid does not assume
            any responsibility for verifying the material, content and
            information including any information pertaining to Vehicle sole
            through Bulkybid Sale on the Platform and is not responsible for any
            damages, losses, expenses or other loss, claim incurred by any
            BulkyBid as a result of relying on any material or content including
            content of the Catalogue posted for an Bulkybid Sale on the
            Platform, and the bulkybid will display the Vehicles with such
            details and content as Seller will inform the bulkybid for purpose
            of Bulkybid Sale.
          </p>
          <p className="mb-4">
            <b>9.6</b> BulkyBid acknowledges and agrees that the bulkybid is
            only facilitating the Bulkybid Sale through its Platform along with
            related services such as preliminary inspection (only in certain
            cases, if expressly mentioned in the Catalogue for any Bulkybid
            Sale), support in vehicle transfer and so on which are provided on a
            commercially reasonable effort basis.
          </p>
          <p className="mb-4">
            <b>9.7</b> BulkyBid hereby agrees and acknowledges that the listed
            Vehicles for Bulkybid Sale on the Platform is /are on a "AS IS WHERE
            IS" basis, listed directly by the Seller and the bulkybid does not
            in any way, expressly or impliedly, give any certification or
            warranties to the BulkyBid, until and unless explicitly notified so.
          </p>

          <p className="mb-4">
            <b>9.8</b> The BulkyBid understand and expressly agrees that the
            bulkybid shall not be liable for any direct, indirect, incidental,
            special, consequential or exemplary damages, including but not
            limited to, damages for loss of profits, goodwill, use, data or
            other intangible losses (even if nothing has been advised of the
            possibility of such damages).
          </p>
          <p className="mb-4">
            <b>9.9</b> Any rights, title and interest to the Vehicle purchased
            through the Bulkybid Sale will be transferred in the name of the
            BulkyBid or his authorised representative only after receipt of the
            Total Price by the Seller and payment of Success Fee to the
            bulkybid, subject always to the Seller's right to cancel any Winning
            Bid in accordance with these Terms and Conditions.
          </p>
          <p className="mb-4">
            <b>9.10</b> BulkyBid may collect the Vehicle purchased through
            Bulkybid Sale, from such premises (as specified in the Catalogue)
            within 2 (two) days of payment of the Total Price to the Seller or
            as notified in the terms in the Catalogue or as expressly notified
            by the Seller in writing, whichever is later . In the event of a
            further delay in the delivery/pick up of the Vehicle, BulkyBid shall
            be liable to pay the Vehicle parking charges as calculated and
            demanded by the Seller and/or bulkybid.
          </p>
          <p className="mb-4">
            <b>9.11</b> Seller's liability in respect of the Vehicle purchase by
            the BulkyBid shall end upon transfer of all right, title and
            interest to the BulkyBid as a new owner of the Vehicle. BulkyBid
            alone shall be responsible for all risk, cost and consequences after
            such transfer of right, title and interest in the name of BulkyBid.
            For purpose of this Agreement all the right, title, interest in a
            Vehicle shall be deemed to be transferred in favour of the BulkyBid
            immediately after receipt of the Total Price by the Seller and
            simultaneous delivery of possession of the Vehicle to the successful
            BulkyBid. Upon completion of sale and transfer of ownership the
            BulkyBid must register itself as the owner/keeper of each Vehicle
            purchased through the Bulkybid Sale, with the respective Regional
            Transport Authority within such time frame as may be prescribed by
            applicable law. Upon transfer of the ownership of Vehicle in the
            name of the BulkyBid the BulkyBid alone shall be responsible and
            liable for any penalty, fees, charges and any other legal demands
            relating to each Vehicle purchased by the BulkyBid.
          </p>
          <p className="mb-4">
            <b>9.12 </b>Any Vehicle which will remaining uncollected from the
            premises mentioned in the Catalogue after transfer of Ownership of
            the Vehicle in the name of the BulkyBid shall be at absolute risk of
            the BulkyBid and the Seller shall not be liable for any loss or
            damage to the Vehicle howsoever caused.
          </p>
          <p className="mb-4">
            <b>9.13</b> BulkyBid expressly agree, acknowledge and authorise
            bulkybid to use BulkyBid's personal information, provided by you to
            us, for the purposes of processing the payments (of Membership Fee,
            Security Deposit etc.).
          </p>
          <p className="mb-4">
            <b>9.14</b> BulkyBid hereby agree they have affirmatively requested
            to receive marketing and promotional materials via mail, e-mail, and
            facsimile.
          </p>

          <p className="mb-4">
            <b>9.15</b> BulkyBid shall not to assign, transfer or novate any and
            all of BulkyBid's rights or obligations under these BulkyBid Terms
            or in respect of any Vehicle purchased by BulkyBid by in an Bulkybid
            Sale conducted through the Platform. bulkybid and/or the Seller may
            assign, transfer or novate any of their respective rights and
            obligations under this BulkyBid Terms or Bulkybid Sale, where the
            bulkybid and/or Seller, as the case may be, has a good reason to do
            so
          </p>
          <p className="mb-4">
            <b>9.16</b> bulkybid shall have no liability to the BulkyBid for any
            delay to performance (and the time for performance shall be and is
            extended accordingly) if that delay is due to circumstances beyond
            bulkybid's reasonable control, including any delay or failure on
            part of the Seller to complete the transaction.
          </p>

          <p className="mb-4">
            <b>9.17</b> This BulkyBid Terms contain the entire understanding and
            agreement between (a) registered BulkyBid/you and bulkybid with
            regard to your use of the Platform and availing the facility of
            participation in an Bulkybid Sale by a registered BulkyBid and
            registered BulkyBid's/ your purchase of any Vehicle pursuant to the
            Bulkybids or other facilities; and (b) BulkyBid/you and the Seller
            with respect to any Vehicle you purchase through the Platform by
            participation in an Bulkybid Sale. No representation of our
            employees, officers or agents shall represent an addition or
            amendment to this BulkyBid Terms unless the same has been set out in
            writing and signed by the authorised representative of the bulkybid.
          </p>
        </div>
      </div>
      {/*TERM  */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">10. TERM</h1>

        <div className="mx-6 mb-6">
          <p className="mb-4">
            This agreement shall be valid and effective from the date of
            successful registration of BulkyBid on the Platform after payment of
            the Membership Fees, Security Deposit and submission of the KYC
            Documents and will be valid for a period of one (1) year therefrom
            ("Initial Subscription Period"). The subscription/membership as a
            registered BulkyBid may be renewed after payment of annual
            Membership Fee, before expiry of the Initial Subscription Period.
          </p>
        </div>
      </div>
      {/* REPRESENTATION AND WARRANTIES */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">
          11. REPRESENTATION AND WARRANTIES
        </h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            <b>11.1</b> The BulkyBid hereby represents and warrants that:
          </p>
          <div className="mx-6">
            <p>
              (1.i) It has full power and authority to carry on its business;
            </p>
            <p>
              (1.ii) The execution and delivery of this BulkyBid Terms and all
              other agreements contemplated hereby will not result in breach of
              any terms and conditions, or constitute default under applicable
              laws or other obligations to which it is bound or violate any
              rule, regulation or law of any Government or any order, judgment
              or decree of any court or government body;
            </p>
            <p>
              (1.iii) The execution and delivery of this Agreement and the other
              acts/covenants contemplated hereby have been duly authorized by
              all necessary board resolution and shareholder's actions,
              required, if any.
            </p>
            <p>
              (1.iv) It is not in violate or contravention of applicable laws,
              rules and regulation governing this BulkyBid Terms.
            </p>
          </div>
        </div>
      </div>
      {/* CONFIDENTIALITY */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">12. CONFIDENTIALITY</h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            <b>12.1 </b>Confidential Information shall mean any proprietary
            information of bulkybid, including but not limited to Bulkybid Sale,
            Bulkybid Sale process flow, look and feel of the Platform, Platform,
            technical and artistic information relating to bulkybid's Platform
            or business, details pertaining to Sellers, other registered
            BulkyBids on the Platform, customers, clients, vendors,
            developments, inventions, technology, etc. Confidential Information
            will include all information (whether available in written, oral or
            machine-readable form) relating to the business or affairs of the
            bulkybid, including (without limitation) information relating to
            existing or future systems, software, hardware, products and
            services, and those in development, and accompanying marketing
            plans, details of employees, agents and business strategies, etc.;
          </p>
          <p className="mb-4">
            <b>12.2</b> The Parties understand and acknowledge that, whether in
            the course of performance of this Agreement or otherwise, it will
            receive or become aware of Confidential Information of the other
            Party.
          </p>
          <p className="mb-4">
            <b>12.3 </b>The Client agree that it shall use the Confidential
            Information of the bulkybid only as follows:
          </p>
          <div className="mx-6">
            <p>
              (a.i.i) to use such Confidential Information only in relation to
              the Agreement;
            </p>
            <p>
              (a.i.ii) not to disclose any such Confidential Information or any
              part thereof to a person outside the Client's business
              organization for any purposes unless expressly authorized by the
              bulkybid;
            </p>
            <p>
              (a.i.iii) to limit dissemination of such Confidential Information
              to persons within the receiving Party's business organization who
              are directly involved in the performance of this Agreement and
              have a need to use such Confidential Information;
            </p>
            <p className="mb-4">
              (a.i.iv) to safeguard the Confidential Information to the same
              extent that it safeguards its own confidential materials or data.
            </p>
          </div>
          <p className="mb-4">
            <b> 12.4</b> The Parties hereby agree that Confidential Information
            shall not include any of the following information that: (i) is
            independently developed by the BulkyBid without reference to any
            Confidential Information of the bulkybid; (ii) is subsequently
            learned from a third Party without a duty of confidentiality; (iii)
            is as of the time of its disclosure part of the public domain; (iv)
            at the time of disclosure was already in the possession of the
            bulkybid;
          </p>
          <p className="mb-4">
            <b>12.5</b> The receiving Party shall immediately upon becoming
            aware of any unauthorized disclosure, give notice to the other Party
            of such unauthorized disclosure, misuse, theft or other loss of
            Confidential Information, whether inadvertently or otherwise.
          </p>
          <p className="mb-4">
            <b>12.6 </b> The Parties' obligations under this Clause shall extend
            to the non-publicizing of any dispute arising out of this Agreement
            or use of facility provided through Platform.
          </p>
          <p className="mb-4">
            <b>12.7</b> The terms of this clause shall continue in full force
            and effect for a period of three (3) years from the date of
            disclosure of such Confidential Information.
          </p>
        </div>
      </div>
      {/*INDEMNIFICATION  */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">13. INDEMNIFICATION</h1>
        <div className="mx-3 mb-6">
          <p className="mb-2">
            BulkyBid hereby agrees to defend, indemnify and hold harmless
            bulkybid and its shareholders, directors, officers, employees,
            agents, affiliates, licensors and suppliers harmless from any and
            all claims, demands, causes of action, debts, liabilities, costs and
            expenses, arising in any way from (a) BulkyBid's misuse of Platform
            or the services provided hereunder, (b) BulkyBid's placement or
            transmission of any message, content, information, software or other
            materials through Platform or using the services associated thereto
            (c) BulkyBid's breach or violation of the law, this Agreement, the
            terms and conditions or the privacy policy or (d) BulkyBid's
            performance, failure to perform or improper performance under this
            Agreement (e) any gross negligence or wilful misconduct of any
            personnel/staff/employee or subcontractor of BulkyBid; (f) breach of
            any representation, warranty or covenant of the BulkyBid contained
            herein;(g) BulkyBid's dispute with the Seller (f) any demand,
            notice, claims, penalty, levies, dues and prosecution
            demanded/instituted by any governmental authority or third party
            arising out of BulkyBid's failure to fulfil its obligations under
            this Agreement or Bulkybid Sale; (g) bodily injury or property
            damage occurring on Seller's Premises; (h) the BulkyBid's failure to
            comply with applicable laws or regulations; and (j) the BulkyBid's
            sale or transfer of Vehicles to third parties.
          </p>
        </div>
      </div>
      {/*SUSPENSION AND TERMINATION  */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">
          14. SUSPENSION AND TERMINATION
        </h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            <b>14.1</b> Either Party may terminate this Agreement without cause
            at any time by providing the other party a written notice of 30
            (Thirty) days.
          </p>
          <p className="mb-4">
            <b>14.2 </b>In the event of termination, the bulkybid has the right
            to adjust the outstanding Membership Fee, Success Fee, Backout Fee
            and/or any other charges due to it or the Seller from the Security
            Deposit maintained with the bulkybid. In the event that BulkyBid's
            Security Deposit available with bulkybid is not sufficient for
            adjustment, the BulkyBid hereby agrees without any demur or protest
            to pay all such outstanding dues including without limitation dues
            pertaining to the Membership Fee and Success Fee payable by
            BulkyBid.
          </p>
          <div className="mx-6">
            <p>
              (i) Notwithstanding the termination right as mentioned above, in
              the event of any violation by the BulkyBid of this Agreement
              including but not limited to delay or failure in any payment
              obligation as stipulated in this Agreement or violation of any of
              the TOU, BulkyBid Terms and policies of the Platform, the bulkybid
              may at its sole discretion suspend or deactivate the BulkyBid's
              access to the Bulkybid Platform with immediate affect which may be
              reinstated by the bulkybid after the BulkyBid fulfil its
              obligations and completes the required formalities as stated by
              the bulkybid.
            </p>
            <p>
              (ii) The BulkyBid shall be required to pay a re-activation charge
              equivalent to the prevailing Membership Fee ("Reactivation Fee")
              or such other re-activation fee as may be prescribed by bulkybid
              for re-activation of BulkyBid's account.
            </p>
            <p>
              (iii) Subject to fulfilment of all the obligations of the BulkyBid
              under this BulkyBid Terms, TOU and terms of Bulkybid Sale, and
              payment of all the due pertaining to Membership Fee, Total Price,
              Success Fee, the BulkyBid may terminate this Agreement by
              deactivating his/her BulkyBid account from the Platform at any
              time without assigning any reason whatsoever.
            </p>
            <p className="mb-4">
              (iv)<b> CONSEQUENCES OF TERMINATION</b>
            </p>
            <div className="mx-8">
              <p className="mb-4">
                (a) All the BulkyBid's rights under this Agreement shall cease
                immediately on termination and no refund whatsoever shall be
                paid to the BulkyBid by the bulkybid or Seller and bulkybid
                shall not be liable or responsible for loss of anticipated
                profits and any other claims or losses in respect of such
                termination. BulkyBid hereby waives any claim to receive any
                compensation as a consequence of the termination of this
                Agreement.
              </p>
              <p className="mb-4">
                (b) The provisions of this Agreement shall, to the extent stated
                or necessarily implied, survive the termination thereof.
              </p>
              <p className="mb-4">
                (c) Termination of this Agreement for whatever cause shall be
                without prejudice to any rights or obligations of any Party
                hereto in respect of this Agreement which have arisen prior to
                such termination or expiration.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*LIMITATION OF LIABILITY */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">
          15. LIMITATION OF LIABILITY
        </h1>
        <div className="mx-6 mb-6">
          <p className="mb-4">
            In no event shall bulkybid, its shareholders, directors, officers,
            employees, agents, affiliates, licensors or suppliers be liable
            hereunder for any consequential, exemplary, special, indirect,
            incidental or punitive damages or lost revenue, lost profits or
            anticipates business (even if they have been advised of possibility
            of such damages) arising from or relating to this Agreement, the
            subject matter hereof, the Platform and services provided hereunder,
            the sale, distribution, use of, or inability to use any Vehicle,
            including but not limited to damages arising from information
            provided by, to or through the Platform, or any other damages,
            however caused, under any theory of liability, including without
            limitation tort (including negligence), contract or otherwise.
            Without limiting the generality of foregoing, bulkybid does not
            warrant that the Platform will operate error free or without
            interruption. The aggregate liability of bulkybid, its shareholders,
            directors, officers, agents, affiliates, licensors, and suppliers to
            BulkyBid for any damages arising out from or relating to this
            Agreement, the subject matter hereof, the Platform or services
            provided hereunder, however caused under any theory of liability,
            shall not exceed the Membership Fee paid by the BulkyBid to the
            bulkybid for the calendar year during which such claim accrue.
          </p>
        </div>
      </div>
      {/*GENERAL TERMS  */}
      <div className="mb-6">
        <h1 className="font-bold text-lg mb-2 mx-3">16. GENERAL TERMS</h1>
        <div className="mx-6 mb-6">
          <h1 className="">
            <b>16.1 Independent Contractors</b>
          </h1>

          <p className="mb-4">
            The Parties are entering this Agreement as independent contractors,
            and this Agreement will not be construed to create a partnership,
            joint venture, or employment relationship between them. Neither
            Party will represent itself to be an employee or agent of the other
            or enter into any agreement or legally binding commitment or
            statement on the other's behalf or in the other's name.
          </p>
          <p className="">
            <b>16.2 Governing Law and Jurisdiction</b>
          </p>
          <p className="mb-4">
            This Agreement shall be governed by the laws of India and the courts
            of Jaipur, Rajasthan shall have exclusive jurisdiction for matters
            pertaining to this Agreement.
          </p>
          <p className="">
            <b>16.3 Waiver</b>
          </p>
          <p className="mb-4">
            To be effective, any waiver by bulkybid of any of its rights or
            BulkyBid's obligations under this Agreement must be made in a
            writing signed by bulkybid. Waiver of any breach of any term or
            condition of this Agreement by bulkybid will not be deemed a waiver
            of any prior or subsequent breach. No failure or forbearance by
            bulkybid to insist upon or enforce performance by BulkyBid of any of
            the provisions of this Agreement or to exercise any rights or
            remedies under this Agreement or otherwise at law or in equity will
            be construed as a waiver or relinquishment to any extent of
            bulkybid's right to assert or rely upon any such provision, right or
            remedy in that or any other instance; rather, the same will be and
            remain in full force and effect.
          </p>
          <p className="">
            <b>16.4 Severability</b>
          </p>

          <p className="mb-4">
            If any provision of this Agreement is invalid or unenforceable in
            any jurisdiction, the other provisions herein will remain in full
            force and effect in such jurisdiction and will be liberally
            construed in order to effectuate the purpose and intent of this
            Agreement, and the invalidity or unenforceability of any provision
            of this Agreement in any jurisdiction will not affect the validity
            or enforceability of any such provision in any other jurisdiction.
          </p>
          <p className="">
            <b>16.5 Entire Agreement</b>
          </p>
          <p className="mb-4">
            This Agreement (including all Attachments hereto, and all documents
            incorporated herein by reference): (a) represents the entire
            agreement between the Parties with respect to the subject matter
            hereof and supersedes any proposals, representations previous or
            contemporaneous oral or written agreements and any other
            communications between the Parties regarding such subject matter;
            and (b) may be amended or modified only by a written instrument
            signed by a duly authorized representative of each Party.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermAndCondition;
