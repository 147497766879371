import React from "react";

function PrivacyAndPolicy() {
  return (
    <div className=" font-poppins mx-8 mt-8">
      <h1 className="font-extrabold text-2xl text-center mb-4">
        Privacy Policy
      </h1>
      <p className="mb-6 font-semibold">
        This document is an electronic record in terms of Information Technology
        Act, 2000 and the amended provisions pertaining to electronic records in
        various statutes as amended by the Information Technology Act, 2000.
        This electronic record is generated by a computer system and does not
        require any physical or digital signatures
      </p>
      <p className="mb-6">
        This Privacy Policy applies only to this Website to the exclusion of any
        third party websites. The Website may provide links to other sites which
        it believes may be of interest to the users of the Website. We do not
        guarantee the privacy standards of those sites to which it links or be
        responsible for the contents of sites other than this one, and this
        Privacy policy is not intended to be applicable to any linked, third
        party site.
      </p>
      <div>
        <h1 className="font-bold ">Information you provide</h1>
        <p className="mb-4">
          As a Registered User, we ask you for Registration Data (such as your
          name, company name, phone nos., email address, postal address, PAN,
          etc.) which we maintain in encrypted form on secure servers. You can
          decline to submit personal information to any of our services, in
          which case the Website may not be able to provide any or all services
          to you.
        </p>
      </div>
      <ul class="mx-6 list-disc list-outside text-gray-900 mb-6">
        <li>
          <b>Cookies:</b> When you visit the Website, we send one or more
          cookies, a small file containing a string of characters to your
          computer that uniquely identifies your browser. We use cookies to
          improve the quality of our service by storing user preferences and
          tracking user trends, such as how people use the Website, we also uses
          cookies in for advertising services to help advertisers and publishers
          serve and manage ads across the web.
        </li>
        <li>
          <b>Log information:</b> When you access the Website, our servers
          automatically record information that your browser sends whenever you
          visit a Website. These server logs may include information such as
          your web request, Internet Protocol address, browser type, browser
          language, the date and time of your request and one or more cookies
          that may uniquely identify your browser. Also, in order to protect you
          from fraud, phishing, and other misconduct, we may collect information
          about your interaction with our servers. Any such information we
          collect will only be used to detect and prevent fraud or other
          misconduct.
        </li>
        <li>
          <b>User communications: </b>When you send email or other
          communications to the Website, we may retain those communications in
          order to process your inquiries, respond to your requests and improve
          our services.
        </li>
        <li>
          <b>Email Addresses</b> If you provide us your email address, we may
          email you about our website or our services, or to inquire about your
          experience using our website or sites operated by others. From time to
          time, we'll also send you emails that may include automotive
          information, special promotions and other commercial offers that we
          think may be of interest to you. Though we hope you'll find these
          communications informative and useful, if you don't, you can always
          unsubscribe by following the simple instructions included in each
          email.
        </li>
        <li>
          <b>Links:</b> The Website may present links in a format that enables
          us to keep track of whether these links have been followed. We use
          this information to improve the quality of our search technology,
          customized content and advertising.
        </li>
      </ul>
      <p className="mb-4">
        We only process personal information for the purposes described in this
        Privacy Policy and/or the supplementary privacy notices for specific
        services. In addition to the above, such purposes include:
      </p>
      <ul class=" list-disc list-inside mx-6 mb-6">
        <li>
          Providing our services to Users, including the display of customized
          content and advertising;
        </li>
        <li>
          Auditing, research and analysis in order to maintain, protect and
          improve our services;
        </li>
        <li>Ensuring the technical functioning of our network; and</li>
        <li> Developing new services</li>
      </ul>
      <h1 className="font-bold  ">Information Sharing</h1>
      <p className="mb-2">
        We only share personal information with other companies or individuals
        in the following limited circumstances:
      </p>
      <ul class=" list-disc list-outside mb-6 mx-6">
        <li>
          We may share with third parties certain pieces of aggregated,
          non-personal information, such as the number of users who searched for
          a particular term, for example, or how many users clicked on a
          particular advertisement. Such information does not identify you
          individually.
        </li> 
        <li>
          We provide such information to our subsidiaries, affiliated companies
          or other trusted businesses or persons for the purpose of processing
          personal information on our behalf. We require that these parties
          agree to process such information based on our instructions and in
          compliance with this Privacy Policy and any other appropriate
          confidentiality and security measures.
        </li>
        <li>
          We have a good faith belief that access, use, preservation or
          disclosure of such information is reasonably necessary to (a) satisfy
          any applicable law, regulation, legal process or enforceable
          governmental request, (b) enforce applicable Terms of Use, including
          investigation of potential violations thereof, (c) detect, prevent, or
          otherwise address fraud, security or technical issues, or (d) protect
          against imminent harm to the rights, property or safety of the
          Website, its users or the public as required or permitted by law.
        </li>
        <li>
          In the event that the business of this Website and the customer data
          connected with it is merged, sold, assigned or transferred, in which
          case we would require the buyer, assignee or transferee to treat
          personal data in accordance with this Privacy Policy.
        </li>
      </ul>
      <h1 className="font-bold">Information Security</h1>
      <p className="mx-2  mb-6">
        Information gathered on the Website is stored within BulkyBid
        controlled servers (or servers hosted on our behalf) that are in a
        protected environment. We take appropriate security measures to protect
        against unauthorized access to or unauthorized alteration, disclosure or
        destruction of data. These include internal reviews of our data
        collection, storage and processing practices and security measures, as
        well as physical security measures to guard against unauthorized access
        to systems where we store personal data. However, no physical or
        electronic security system is impenetrable. We cannot guarantee the
        security of our servers or databases, nor can we guarantee that
        information you supply won't be intercepted while being transmitted to
        us over the Internet. Although we work very hard to protect your
        privacy, we do not promise, and you should not expect, that your
        personal information or private communications will always remain
        private.
      </p>
      <h1 className="font-bold ">
        Accessing and updating personal information
      </h1>
      <p className="mx-2  mb-6">
        When you use the Website, we make good faith efforts to provide you with
        access to your personal information and either to correct this data if
        it is inaccurate or to delete such data at your request if it is not
        otherwise required to be retained by law or for legitimate business
        purposes. We ask Users to identify themselves and the information
        requested to be accessed, corrected or removed before processing such
        requests, and we may decline to process requests that are unreasonably
        repetitive or systematic, require disproportionate technical effort,
        jeopardize the privacy of others, or would be extremely impractical, or
        for which access is not otherwise required. Some of our services have
        different procedures to access, correct or delete personal information
      </p>
      <h1 className="font-bold ">Changes to this Privacy Policy</h1>
      <p className="mx-2  mb-6">
        Please note that this Privacy Policy may change from time to time. We
        will post any Privacy Policy changes on this page and, if the changes
        are significant, we will provide a more prominent notice (including, for
        certain services, email notification of Privacy Policy changes). By
        visiting and using BulkyBid, you agree that your visit, and any
        dispute over privacy, is governed by this Privacy Statement and our User
        Agreement.
      </p>
    </div>
  );
}




export default PrivacyAndPolicy